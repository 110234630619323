<template>
  <div>
    <div v-html="page.html"></div>

    <b-row v-if="!skipPersonnr">
      <b-col lg="6">
        <b-form-group id="personnr-group" :label="$t('MEMBER.PERSONNR')" label-for="personnr">
          <b-form-input
            id="personnr"
            name="personnr"
            :disabled="disabled"
            v-model.trim="$v.form.personnr.$model"
            :placeholder="$t('PAGES.REGISTRATION.PERSONNR_FORMAT')"
            :state="validateState('personnr')"
          />
          <b-form-invalid-feedback>{{$t('PAGES.REGISTRATION.INPUT_PERSONNR')}}</b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row v-if="skipPersonnr">
      <b-col lg="6">
        <p>{{$t('PAGES.REGISTRATION.SELECTED_SKIP_PERSONNR')}}</p>
      </b-col>
    </b-row>

    <b-row v-if="!page.settings.is_mandatory">
      <b-col lg="6">
        <b-form-group>
          <div class="d-flex align-items-center mb-4">
            <label class="checkbox checkbox-lg checkbox-outline checkbox-success">
              <input
                type="checkbox"
                name=""
                v-model="skipPersonnr"
                @click="skipPersonnr = !skipPersonnr"
              />
              <span></span>
            </label>
            <span class="ml-3 cursor-pointer">{{$t('PAGES.REGISTRATION.SKIP_PERSONNR')}}</span>
          </div>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<style lang="scss" scoped>
.checkbox > span {
  background-color: white;
  border: 1px solid #ddd;
}
</style>
<script>
import dayjs from 'dayjs';
import axios from 'axios';

import formatPersonnr, { validate_personnr } from '@/core/services/personnr';
import { isPersonnrValid } from '@/core/services/validators';
import { requiredUnless } from 'vuelidate/lib/validators';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { mapGetters } from 'vuex';


export default {
  name: 'RegisterWizardPersonnrPage',
  mixins: [ toasts ],
  props: ['page', 'page_data', 'ageUpdated', 'isRenew', 'requirements', 'allow_duplicates'],
  emits: ['wizardPageWasUpdated', 'update:page_data', 'doSparLookup', 'isAlreadyRegistered', 'on_ladok_data', 'ageUpdated'],
  data() {
    return {
      local_page_data: {},
      checkedPersonnr: null,
      form: {
        personnr: ''
      },
      disabled: false,
      fmt_personnr: '',
      age: 0,
      skipPersonnr: false,
      hasDoneSparLookup: false
    };
  },
  validations() {
    return {
      form: {
        personnr: {
          required: requiredUnless(function() {
            return this.skipPersonnr;
          }),
          validate_personnr: this.skipPersonnr ? true : validate_personnr,
          isPersonnrValid: this.skipPersonnr ? true : isPersonnrValid
        }
      }
    };
  },
  mounted() {
    if (this.page_data) {
      this.local_page_data = { ...this.page_data };
    }
    this.age = 18;

    this.formWasUpdated();
  },
  watch: {
    page_data: {
      deep: true,
      handler(val) {
        this.local_page_data = { ...val };
      }
    },
    '$v.form': {
      handler() {
        if (!this.$v.$invalid && this.form.personnr) {
          this.fmt_personnr = formatPersonnr(this.form.personnr);
          this.form.personnr = this.fmt_personnr.formatted;
          this.age = this.getAge();

          // SPAR lookup
          if (this.fmt_personnr.valid && !this.hasDoneSparLookup && this.page.settings.spar && !this.isRenew) {
            this.hasDoneSparLookup = true;
            this.$emit('doSparLookup', this.form.personnr);
          }


        }

        this.local_page_data[this.page.id] = {
          ...this.local_page_data[this.page.id],
          form: { ...this.form, age: this.age },
          validation: this.$v
        };
        this.$emit('update:page_data', this.local_page_data);

        this.formWasUpdated();
      },
      deep: true
    },
    age() {
      this.$emit('ageUpdated', this.age);
    },
    skipPersonnr(newValue) {
      if (newValue) {
        this.$emit('wizardPageWasUpdated', this.page.type, { in_personnr: '', fmt_personnr: '', skip: this.skipPersonnr }, true);
        this.$v.form.personnr.$model = '';
        this.age = 18;
      }
    }
  },

  computed: {
    ...mapGetters(['session_customer_id']),
  },
  methods: {

    run_ladok_check(valid_personnr) {
      new Promise(async (resolve, reject)=>{
        if (this.page.settings.check_ladok) {
          const result = await this.check_ladok(valid_personnr);

          if (result) {
            this.$emit('on_ladok_data', result);

            resolve(result);
          }
        }
      });

    },

    async check_ladok(personnr) {
      try {
        const res = await axios.post(`/ladok/checkpersonnr`, {
          personnr: personnr
        });

        if (res.status === 200) {
          return res.data
        }

      }
      catch (err) {
        console.error('check_ladok error', err);
      }

      return null;

    },

    checkIdentification(personnr, email, phone, callback) {

      if (this.allow_duplicates) {
        callback(false, false);
        return;
      }

      
      axios
        .post(`/member/identification/${this.session_customer_id}`, {
          personnr: personnr,
          email: email,
          phone: phone
        })
        .then(res => {

          if (res.status === 404) {
            callback(false, false);
          }
          else if (res.status === 200) {
            callback(true, false);
          }
          else if (res.status === 429) {
            callback(false, true);
          }
          else {
            callback(false, false);
          }
        })
        .catch(err => {
          console.error(err);
        });
    },
    isNextButtonOK() {
      if (this.skipPersonnr) {
        return true;
      }

      const personnr = this.$v.form.personnr.$model;

      let valid = formatPersonnr(personnr);

      if (this.page.settings.is_mandatory) {
        return valid.valid;
      }

      if (personnr.length === 8 && valid.success) {
        return true;
      }
      else if (valid.valid) {
        return true;
      }

      return false;
    },
    getData() {
      let personnr = formatPersonnr(this.$v.form.personnr.$model);

      return {
        personnr: personnr.formatted,
        skip: this.skipPersonnr
      }
    },
    formWasUpdated() {
      let personnr = formatPersonnr(this.$v.form.personnr.$model);

      if (personnr.valid && !this.isRenew && this.checkedPersonnr !== personnr) {
        this.checkedPersonnr = personnr;

        this.checkIdentification(personnr.formatted, undefined, undefined, (is_already_registered, is_rate_limited) => {

          if (is_already_registered) {
            this.$emit('isAlreadyRegistered', personnr.formatted);
            return;
          }

          if ((personnr.valid && this.page.settings.is_mandatory) || (personnr.success && !this.page.settings.is_mandatory)) {
            this.$emit('wizardPageWasUpdated', this.page.type, { in_personnr: personnr.formatted, fmt_personnr: personnr.formatted, skip: this.skipPersonnr }, this.isNextButtonOK());
          }
        });
      }

      if (this.isRenew) {
        if ((personnr.valid && this.page.settings.is_mandatory) || (personnr.success && !this.page.settings.is_mandatory)) {
          this.$emit('wizardPageWasUpdated', this.page.type, { in_personnr: personnr.formatted, fmt_personnr: personnr.formatted, skip: this.skipPersonnr }, this.isNextButtonOK());
        }
      }
    },
    isFieldValid(name) {},
    setForm(data) {
      if (!data.fmt_personnr) return;
      this.$v.form.personnr.$model = data.fmt_personnr;
      // this.disabled = true;
      this.formWasUpdated();
    },
    validateState(name) {
      // if the field is not required, and empty, do gray
      var val = this.$v.form.personnr.$model;

      if (val === null || val === undefined || val === '') {
        return null;
      }

      const is_ok = this.isNextButtonOK();

      if (is_ok) {
        this.run_ladok_check(val);
      }

      return is_ok;
    },
    getAge() {
      if (this.fmt_personnr.valid) {
        return dayjs().diff(
          this.fmt_personnr.year + '-' + this.fmt_personnr.month + '-' + this.fmt_personnr.day,
          'years'
        );
      }

      return 18;
    }
  }
};
</script>
